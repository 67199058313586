
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Anush from '../../Images/Team/Anush.jpg';
import Anushik from '../../Images/Team/Anushik.jpg';
import Eliza from '../../Images/Team/Eliza.jpg';
import Lusine from '../../Images/Team/Lusine.jpg';
import Mariam from '../../Images/Team/Mariam.jpg';
import Marine from '../../Images/Team/Marine.jpg';
import Artak from '../../Images/Team/Artak.jpg';
import Arthur from '../../Images/Team/Arthur.jpg';
import Nare from '../../Images/Team/Nare.jpg';
import Narek from '../../Images/Team/Narek.jpg';
import Tigran from '../../Images/Team/Tigran1.jpg';
import { useTranslation } from 'react-i18next';
import './Team.css'

export default function Team() {
    const { t } = useTranslation();

    return (
        <div>
            <div className={'services-block py-5 mt-5 our-team-main'}>
                <div className={'partners-header-block text-center'}>
                    <div className={'title-design'}></div>
                    <h2 className={'block-title text-white'}>{t('translation.ourTeam')}</h2>
                    <div className="sub-title">{t('translation.getAcquainted')}</div>
                    <span className="bottom-title"></span>
                </div>
            </div>
            <Container className={'mt-5'}>
                <Row>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Lusine} alt="Lusine" className={'w-100 teammate-img'}/>
                       <div className={'team-member-detail'}>
                           <p className={'teammate-name'}>{t('translation.workerName1')}</p>
                           <p className={'teammate-position'}>{t('translation.possitionW1')}</p>
                         <div className={'teammate-info'}>
                             <p style={{opacity:0}}>{t('translation.w1Detail')}</p>
                         </div>
                       </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Artak} alt="Artak" className={'w-100 teammate-img'}/>
                        <div className={'team-member-detail'}>
                            <p className={'teammate-name'}>{t('translation.workerName12')}</p>
                            <p className={'teammate-position'}>{t('translation.possitionW3')}</p>
                            <div className={'teammate-info'}>
                                <p>{t('translation.w12Detail')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Anush} alt="Anush" className={'w-100 teammate-img'}/>
                        <div className={'team-member-detail'}>
                            <p className={'teammate-name'}>{t('translation.workerName2')} </p>
                            <p className={'teammate-position'}>{t('translation.possitionW2')}</p>
                            <div className={'teammate-info'}>
                                <p>{t('translation.w2Detail')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Nare} alt="Nare" className={'w-100 teammate-img'}/>
                        <div className={'team-member-detail'}>
                            <p className={'teammate-name'}>{t('translation.workerName4')}</p>
                            <p className={'teammate-position'}>{t('translation.possitionW2')}</p>
                            <div className={'teammate-info'}>
                                <p>{t('translation.w4Detail')}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Eliza} alt="Eliza" className={'w-100 teammate-img'}/>
                        <div className={'team-member-detail'}>
                            <p className={'teammate-name'}>{t('translation.workerName5')}</p>
                            <p className={'teammate-position'}>{t('translation.possitionW2')}</p>
                            <div className={'teammate-info'}>
                                <p>{t('translation.w5Detail')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Marine} alt="Marine" className={'w-100 teammate-img'}/>
                        <div className={'team-member-detail'}>
                            <p className={'teammate-name'}>{t('translation.workerName6')}</p>
                            <p className={'teammate-position'}>{t('translation.possitionW6')}</p>
                            <div className={'teammate-info'}>
                                <p>{t('translation.w6Detail')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Anushik} alt="Anushik" className={'w-100 teammate-img'}/>
                        <div className={'team-member-detail'}>
                            <p className={'teammate-name'}>{t('translation.workerName7')} </p>
                            <p className={'teammate-position'}>{t('translation.possitionW6')}</p>
                            <div className={'teammate-info'}>
                                <p>{t('translation.w7Detail')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Narek} alt="Narek" className={'w-100 teammate-img'}/>
                        <div className={'team-member-detail'}>
                            <p className={'teammate-name'}>{t('translation.workerName8')}</p>
                            <p className={'teammate-position'}>{t('translation.possitionW6')}</p>
                            <div className={'teammate-info'}>
                                <p>{t('translation.w8Detail')}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Tigran} alt="Tigran" className={'w-100 teammate-img'}/>
                        <div className={'team-member-detail'}>
                            <p className={'teammate-name'}>{t('translation.workerName11')}</p>
                            <p className={'teammate-position'}>{t('translation.possitionW6')}</p>
                            <div className={'teammate-info'}>
                                <p>{t('translation.w11Detail')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                        <img src={Arthur} alt="Arthur" className={'w-100 teammate-img'}/>
                        <div className={'team-member-detail'}>
                            <p className={'teammate-name'}>{t('translation.workerName13')}</p>
                            <p className={'teammate-position'}>{t('translation.possitionW6')}</p>
                            <div className={'teammate-info'}>
                                <p>{t('translation.w13Detail')}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}